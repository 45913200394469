<template>
  <div v-if="!inline" class="d-flex justify-content-center" :class="spinnerClass">
    <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"
          ><slot>{{ text }}</slot></span
        >
      </div>
      <div class="font-w600 spinner-text">
        <slot>{{ text }}</slot>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center" :class="{ 'justify-content-center': centered }">
    <div class="spinner-grow text-secondary" :class="`spinner-grow${size === 'md' ? '' : `-${size}`}`" role="status">
      <span class="sr-only"
        ><slot>{{ text }}</slot></span
      >
    </div>
    <div class="font-w600 spinner-text ml-2">
      <slot>{{ text }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Spinner',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: 'Loading...'
    }
  },
  computed: {
    spinnerClass() {
      return { [`spinner-${this.size}`]: this.size !== 'md', spinner: this.size === 'md', [`spinner-${this.variant}`]: this.variant };
    }
  }
};
</script>
<style lang="scss">
.spinner {
  .spinner-text {
    margin-top: 0.5rem;
  }
}

.spinner-lg {
  .spinner-border {
    width: 4rem;
    height: 4rem;
    border-width: 0.5em;
  }

  .spinner-text {
    font-size: 1rem;
    margin-top: 0.7rem;
  }
}
.spinner-xl {
  .spinner-border {
    width: 8rem;
    height: 8rem;
    border-width: 0.75em;
  }

  .spinner-text {
    font-size: 2rem;
    margin-top: 0.7em;
  }
}
.spinner-primary {
  .spinner-border {
    border-color: #3c5c61;
    border-right-color: transparent;
  }
  .spinner-text {
    color: #3c5c61;
  }
}
.spinner-white {
  .spinner-border {
    border-color: #fff;
    border-right-color: transparent;
  }
  .spinner-text {
    color: #fff;
  }
}
</style>
