<template>
  <div class="content">
    <Spinner v-if="loading"></Spinner>
    <component
      :is="$route.params.reportType"
      v-else
      :report-data="reportData"
      :start-date="startDate"
      :end-date="endDate"
      :start-date-comparison="startDateComparison"
      :end-date-comparison="endDateComparison"
    ></component>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Spinner from '@/components/Spinner';
import ReportPortfolio from './ReportPortfolio';
import ReportPortfolioTable from './ReportPortfolioTable';

export default {
  name: 'PortfolioReportView',
  components: {
    Spinner,
    report: ReportPortfolio,
    table: ReportPortfolioTable
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      reportData: 'assetGroup/reportData'
    }),
    startDate() {
      return moment().subtract(1, 'year').startOf('year').toDate();
    },
    endDate() {
      return moment().subtract(1, 'year').endOf('year').toDate();
    },
    startDateComparison() {
      return moment().subtract(2, 'year').startOf('year').toDate();
    },
    endDateComparison() {
      return moment().subtract(2, 'year').endOf('year').toDate();
    }
  },
  async mounted() {
    await this.getReportData({ id: this.$route.params.id });

    this.loading = false;
  },
  methods: {
    ...mapActions({
      downloadReport: 'report/download',
      getReportData: 'assetGroup/getReportData'
    })
  }
};
</script>
