var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm.loading ? _c('Spinner') : _c(_vm.$route.params.reportType, {
    tag: "component",
    attrs: {
      "report-data": _vm.reportData,
      "start-date": _vm.startDate,
      "end-date": _vm.endDate,
      "start-date-comparison": _vm.startDateComparison,
      "end-date-comparison": _vm.endDateComparison
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }