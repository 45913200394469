var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.inline ? _c('div', {
    staticClass: "d-flex justify-content-center",
    class: _vm.spinnerClass
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.text))])], 2)]), _c('div', {
    staticClass: "font-w600 spinner-text"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.text))])], 2)])]) : _c('div', {
    staticClass: "d-flex align-items-center",
    class: {
      'justify-content-center': _vm.centered
    }
  }, [_c('div', {
    staticClass: "spinner-grow text-secondary",
    class: "spinner-grow".concat(_vm.size === 'md' ? '' : "-".concat(_vm.size)),
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.text))])], 2)]), _c('div', {
    staticClass: "font-w600 spinner-text ml-2"
  }, [_vm._t("default", [_vm._v(_vm._s(_vm.text))])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }