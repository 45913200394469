var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "report"
  }, [_c('ReportPage', [_c('div', {
    staticClass: "report-title"
  }, [_c('h1', [_c('small', [_vm._v("Sustainability Report")]), _c('br'), _vm._v(_vm._s(_vm.reportData.portfolio.name))]), _c('p', {
    staticClass: "font-size-h3 mb-0"
  }, [_c('strong', [_vm._v("Period:")]), _vm._v(" Jan 2020 - Dec 2020")]), _c('p', {
    staticClass: "font-size-h5"
  }, [_c('strong', [_vm._v("Compared to:")]), _vm._v(" Jan 2019 - Dec 2019")])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-contents"
  }, [_c('h2', [_vm._v("Contents")]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Overview")]), _c('span', [_vm._v("3")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Energy")]), _c('span', [_vm._v("4")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Water")]), _c('span', [_vm._v("5")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Waste")]), _c('span', [_vm._v("6")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Emissions")]), _c('span', [_vm._v("7")])]), _c('div', {
    staticClass: "report-contents--line"
  }, [_c('span', [_vm._v("Projects")]), _c('span', [_vm._v("8")])])])]), _c('ReportPage', [_c('h2', [_vm._v("Overview")]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.assets.current.length))]), _c('ReportPercentage', {
    staticClass: "report-stat--change",
    attrs: {
      "current": _vm.assets.current.length,
      "previous": _vm.assets.previous.length
    }
  }), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Assets")])], 1), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.assets.current.reduce(function (sum, a) {
    return sum + a.floorArea;
  }, 0))) + " m"), _c('sup', [_vm._v("2")])]), _c('ReportPercentage', {
    staticClass: "report-stat--change",
    attrs: {
      "current": _vm.assets.current.reduce(function (sum, a) {
        return sum + a.floorArea;
      }, 0),
      "previous": _vm.assets.previous.reduce(function (sum, a) {
        return sum + a.floorArea;
      }, 0)
    }
  }), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Floor Area")])], 1), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£" + _vm._s(_vm.numberWithCommas(_vm.assets.current.reduce(function (sum, a) {
    return sum + Number(a.gav);
  }, 0))))]), _c('ReportPercentage', {
    staticClass: "report-stat--change",
    attrs: {
      "current": _vm.assets.current.reduce(function (sum, a) {
        return sum + Number(a.gav);
      }, 0),
      "previous": _vm.assets.previous.reduce(function (sum, a) {
        return sum + Number(a.gav);
      }, 0)
    }
  }), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("GAV")])], 1), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.accounts.current.length)))]), _c('ReportPercentage', {
    staticClass: "report-stat--change",
    attrs: {
      "current": _vm.accounts.current.length,
      "previous": _vm.accounts.previous.length
    }
  }), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Accounts")])], 1)]), _c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("Asset Type Distribution")]), _c('p', [_vm._v("The distribution of asset types for this portfolio.")]), _c('Chart', {
    attrs: {
      "options": _vm.pieAssetTypeOptions
    }
  })], 1), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Electricity Data")]), _c('span', [_c('i', {
    staticClass: "fa fa-bolt fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("1,544 kWh")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("12 kWh/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage per area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£345.43")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£2.12/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost per area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 kWh")]), _c('p', [_vm._v("Previous usage: 1,323 kWh")]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 kWh")]), _c('p', [_vm._v("Previous usage: 1,323 kWh")]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Asset Performance")]), _c('p', [_vm._v("Highest and lowest performing assets excluding any zero-usage assets.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Best Perfomers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sub', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sub', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("3.34")]), _c('td', [_vm._v("£2.43")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("6.32")]), _c('td', [_vm._v("£2.20")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Ortiz Square")]), _c('td', [_vm._v("12.44")]), _c('td', [_vm._v("£1.20")])])])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Worst Performers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sub', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sub', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Bednar Palace")]), _c('td', [_vm._v("2.33")]), _c('td', [_vm._v("£0.94")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Gusikowski Centre")]), _c('td', [_vm._v("1.44")]), _c('td', [_vm._v("£0.54")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Kiehn Market")]), _c('td', [_vm._v("0.32")]), _c('td', [_vm._v("£0.23")])])])])])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Gas Data")]), _c('span', [_c('i', {
    staticClass: "fad fa-burn fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("1,544 kWh")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("12 kWh/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage per area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£345.43")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£2.12/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost per area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 kWh")]), _c('p', [_vm._v("Previous usage: 1,323 kWh")]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 kWh")]), _c('p', [_vm._v("Previous usage: 1,323 kWh")]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Asset Performance")]), _c('p', [_vm._v("Highest and lowest performing assets excluding any zero-usage assets.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Best Perfomers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sub', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sub', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("3.34")]), _c('td', [_vm._v("£2.43")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("6.32")]), _c('td', [_vm._v("£2.20")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Ortiz Square")]), _c('td', [_vm._v("12.44")]), _c('td', [_vm._v("£1.20")])])])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Worst Performers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sub', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sub', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Bednar Palace")]), _c('td', [_vm._v("2.33")]), _c('td', [_vm._v("£0.94")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Gusikowski Centre")]), _c('td', [_vm._v("1.44")]), _c('td', [_vm._v("£0.54")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Kiehn Market")]), _c('td', [_vm._v("0.32")]), _c('td', [_vm._v("£0.23")])])])])])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Water Data")]), _c('span', [_c('i', {
    staticClass: "fad fa-tint fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("1,544 m"), _c('sup', [_vm._v("3")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("12 m"), _c('sup', [_vm._v("3")]), _vm._v("/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage per area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£345.43")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£2.12/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost per area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 m"), _c('sup', [_vm._v("3")])]), _c('p', [_vm._v("Previous usage: 1,323 m"), _c('sup', [_vm._v("3")])]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 m"), _c('sup', [_vm._v("3")])]), _c('p', [_vm._v("Previous usage: 1,323 m"), _c('sup', [_vm._v("3")])]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Asset Performance")]), _c('p', [_vm._v("Highest and lowest performing assets excluding any zero-usage assets.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Best Perfomers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("m"), _c('sup', [_vm._v("3")]), _vm._v("/m"), _c('sup', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sup', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("3.34")]), _c('td', [_vm._v("£2.43")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("6.32")]), _c('td', [_vm._v("£2.20")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Ortiz Square")]), _c('td', [_vm._v("12.44")]), _c('td', [_vm._v("£1.20")])])])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Worst Performers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sup', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sup', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Bednar Palace")]), _c('td', [_vm._v("2.33")]), _c('td', [_vm._v("£0.94")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Gusikowski Centre")]), _c('td', [_vm._v("1.44")]), _c('td', [_vm._v("£0.54")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Kiehn Market")]), _c('td', [_vm._v("0.32")]), _c('td', [_vm._v("£0.23")])])])])])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Waste Data")]), _c('span', [_c('i', {
    staticClass: "fad fa-dumpster fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("1,544 kWh")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("12 kWh/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Usage per area")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£345.43")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("£2.12/m"), _c('sup', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Cost per area")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Usage")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 kWh")]), _c('p', [_vm._v("Previous usage: 1,323 kWh")]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Cost")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Total usage: 1,544 kWh")]), _c('p', [_vm._v("Previous usage: 1,323 kWh")]), _c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Asset Performance")]), _c('p', [_vm._v("Highest and lowest performing assets excluding any zero-usage assets.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Best Perfomers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sup', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sup', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("3.34")]), _c('td', [_vm._v("£2.43")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("6.32")]), _c('td', [_vm._v("£2.20")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Ortiz Square")]), _c('td', [_vm._v("12.44")]), _c('td', [_vm._v("£1.20")])])])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Worst Performers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sup', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sup', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Bednar Palace")]), _c('td', [_vm._v("2.33")]), _c('td', [_vm._v("£0.94")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Gusikowski Centre")]), _c('td', [_vm._v("1.44")]), _c('td', [_vm._v("£0.54")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Kiehn Market")]), _c('td', [_vm._v("0.32")]), _c('td', [_vm._v("£0.23")])])])])])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Emissions")]), _c('span', [_c('i', {
    staticClass: "fad fa-smog fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("43.32 MTCO2e")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("CO"), _c('sub', [_vm._v("2")]), _vm._v(" Emissions")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("2.32 MTCO2e/m"), _c('sub', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("CO"), _c('sub', [_vm._v("2")]), _vm._v(" Emissions")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("43.32 MTCO2e/m"), _c('sub', [_vm._v("2")])]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("CO"), _c('sub', [_vm._v("2")]), _vm._v(" Emissions")])])]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('Chart', {
    attrs: {
      "options": _vm.energyChartOptions
    }
  })], 1)]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Asset Performance")]), _c('p', [_vm._v("Highest and lowest performing assets excluding any zero-usage assets.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Best Perfomers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kWh/m"), _c('sub', [_vm._v("2")])])]), _c('th', [_vm._v(" Cost "), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("£/m"), _c('sub', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("3.34")]), _c('td', [_vm._v("£2.43")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("6.32")]), _c('td', [_vm._v("£2.20")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Ortiz Square")]), _c('td', [_vm._v("12.44")]), _c('td', [_vm._v("£1.20")])])])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Worst Performers")]), _c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Asset Name")]), _c('th', [_vm._v(" Usage"), _c('br'), _c('small', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v("kg CO2e/m"), _c('sub', [_vm._v("2")])])])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("3.34")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("6.32")])]), _c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Ortiz Square")]), _c('td', [_vm._v("12.44")])])])])])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Projects")]), _c('span', [_c('i', {
    staticClass: "fad fa-tasks fa-2x"
  })])]), _c('div', {
    staticClass: "report-stats"
  }, [_c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("12")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Pending")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("3")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("In Progress")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("43")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-down"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Completed On-time")])]), _c('div', {
    staticClass: "report-stat"
  }, [_c('span', {
    staticClass: "report-stat--value"
  }, [_vm._v("12")]), _c('span', {
    staticClass: "report-stat--change"
  }, [_c('small', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "fa fa-long-arrow-up"
  }), _vm._v(" 25%")])]), _c('br'), _c('span', {
    staticClass: "report-stat--label"
  }, [_vm._v("Completed Late")])])]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Summary")]), _c('p', [_vm._v("Summary of all projects.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('table', {
    staticClass: "table table-borderless table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', [_vm._v("Project Name")]), _c('th', [_vm._v("Asset")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Tasks Completed")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("LED Retrofit")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("In Progress")]), _c('td', [_vm._v("12/36 (33%)")])]), _c('tr', [_c('td', [_vm._v("Solar Panel Installation")]), _c('td', [_vm._v("Bednar Palace")]), _c('td', [_vm._v("In Progress")]), _c('td', [_vm._v("12/54 (23%)")])]), _c('tr', [_c('td', [_vm._v("Solar Panel Installation")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("Completed")]), _c('td', [_vm._v("54/54 (100%)")])])])])])])]), _c('ReportPage', [_c('div', {
    staticClass: "report-page--title"
  }, [_c('h2', [_vm._v("Data Summary")]), _c('span', [_c('i', {
    staticClass: "fad fa-tasks fa-2x"
  })])]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Assets")]), _c('p', [_vm._v("24 assets were used in this report.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('table', {
    staticClass: "table table-vcenter"
  }, [_c('thead', {
    staticClass: "bg-primary text-white"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Asset Details")]), _c('th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Usage (kWh)")]), _c('th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Cost")]), _c('th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Emissions")])]), _c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Location")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Size")]), _c('th', [_vm._v("Electricity")]), _c('th', [_vm._v("Gas")]), _c('th', [_vm._v("Water")]), _c('th', [_vm._v("Electricity")]), _c('th', [_vm._v("Gas")]), _c('th', [_vm._v("Water")]), _c('th', [_vm._v("Electricity")]), _c('th', [_vm._v("Gas")]), _c('th', [_vm._v("Water")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("LED Retrofit")]), _c('td', [_vm._v("Hartmann Wall")]), _c('td', [_vm._v("In Progress")]), _c('td', [_vm._v("12/36 (33%)")])]), _c('tr', [_c('td', [_vm._v("Solar Panel Installation")]), _c('td', [_vm._v("Bednar Palace")]), _c('td', [_vm._v("In Progress")]), _c('td', [_vm._v("12/54 (23%)")])]), _c('tr', [_c('td', [_vm._v("Solar Panel Installation")]), _c('td', [_vm._v("Senger Drive")]), _c('td', [_vm._v("Completed")]), _c('td', [_vm._v("54/54 (100%)")])])])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }