<template>
  <span v-bind="$attrs"
    ><small
      :class="{
        'text-success': getPctDiff(current, previous) * (inverse ? -1 : 1) > 0,
        'text-danger': getPctDiff(current, previous) * (inverse ? -1 : 1) < 0,
        'text-muted': getPctDiff(current, previous) === 0
      }"
      ><i
        class="fa"
        :class="{
          'fa-long-arrow-up': getPctDiff(current, previous) * (inverse ? -1 : 1) > 0,
          'fa-long-arrow-down': getPctDiff(current, previous) * (inverse ? -1 : 1) < 0,
          'fa-minus': getPctDiff(current, previous) === 0
        }"
      ></i>
      {{ getPctDiff(current, previous, abs).toFixed(precision) }}%</small
    >
  </span>
</template>
<script>
export default {
  name: 'ReportPercentage',
  props: {
    current: {
      type: Number,
      required: true
    },
    previous: {
      type: Number,
      required: true
    },
    inverse: {
      type: Boolean,
      required: false,
      default: false
    },
    abs: {
      type: Boolean,
      required: false,
      default: true
    },
    precision: {
      type: Number,
      required: false,
      default: 2
    }
  },
  methods: {
    getPctDiff(a, b, abs = false) {
      const diff = ((a - b) * 100) / b;

      return abs ? Math.abs(diff) : diff;
    }
  }
};
</script>
