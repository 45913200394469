<template>
  <div class="report">
    <ReportPage>
      <div class="report-title">
        <h1><small>Sustainability Report</small><br />University of West London</h1>

        <p class="font-size-h3 mb-0"><strong>Period:</strong> Jan 2020 - Dec 2020</p>
        <p class="font-size-h5"><strong>Compared to:</strong> Jan 2019 - Dec 2019</p>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-contents">
        <h2>Contents</h2>
        <div class="report-contents--line">
          <span>Overview</span>
          <span>3</span>
        </div>
        <div class="report-contents--line">
          <span>Energy</span>
          <span>1</span>
        </div>
        <div class="report-contents--line">
          <span>Water</span>
          <span>3</span>
        </div>
        <div class="report-contents--line">
          <span>Waste</span>
          <span>6</span>
        </div>
        <div class="report-contents--line">
          <span>Emissions</span>
          <span>7</span>
        </div>
        <div class="report-contents--line">
          <span>Projects</span>
          <span>7</span>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <h2>Overview</h2>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">32</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 15%</small> </span
          ><br />
          <span class="report-stat--label">Assets</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">12,982 m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Floor Area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£3,543,000</span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 45%</small> </span
          ><br />
          <span class="report-stat--label">GAV</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">104</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 11%</small> </span
          ><br />
          <span class="report-stat--label">Accounts</span>
        </div>
      </div>
      <h3 class="mb-1">Asset Type Distribution</h3>
      <p>The distribution of asset types compared to the previous period (shown in brackets).</p>
      <Chart :options="pieChartOptions" />
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Electricity Data</h2>
        <span><i class="fa fa-bolt fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">1,544 kWh</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">12 kWh/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage per area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£345.43</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£2.12/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost per area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
      </div>
      <h3 class="mb-2">Asset Performance</h3>
      <p>Highest and lowest performing assets excluding any zero-usage assets.</p>
      <div class="row">
        <div class="col-6">
          <h4 class="mb-2">Best Perfomers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>3.34</td>
                <td>£2.43</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Paragon House Unit G</td>
                <td>6.32</td>
                <td>£2.20</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Vestry Hall</td>
                <td>12.44</td>
                <td>£1.20</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <h4 class="mb-2">Worst Performers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - Teaching Centre</td>
                <td>2.33</td>
                <td>£0.94</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Villiers House Haven Green</td>
                <td>1.44</td>
                <td>£0.54</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Paragon House Unit H</td>
                <td>0.32</td>
                <td>£0.23</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Gas Data</h2>
        <span><i class="fad fa-burn fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">1,544 kWh</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">12 kWh/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage per area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£345.43</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£2.12/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost per area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
      </div>
      <h3 class="mb-2">Asset Performance</h3>
      <p>Highest and lowest performing assets excluding any zero-usage assets.</p>
      <div class="row">
        <div class="col-6">
          <h4 class="mb-2">Best Perfomers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>3.34</td>
                <td>£2.43</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Paragon House Unit G</td>
                <td>6.32</td>
                <td>£2.20</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Vestry Hall</td>
                <td>12.44</td>
                <td>£1.20</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <h4 class="mb-2">Worst Performers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - Teaching Centre</td>
                <td>2.33</td>
                <td>£0.94</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Villiers House Haven Green</td>
                <td>1.44</td>
                <td>£0.54</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Paragon House Unit H</td>
                <td>0.32</td>
                <td>£0.23</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Gas Data</h2>
        <span><i class="fad fa-tint fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">1,544 kWh</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">12 kWh/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage per area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£345.43</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£2.12/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost per area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
      </div>
      <h3 class="mb-2">Asset Performance</h3>
      <p>Highest and lowest performing assets excluding any zero-usage assets.</p>
      <div class="row">
        <div class="col-6">
          <h4 class="mb-2">Best Perfomers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>3.34</td>
                <td>£2.43</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Paragon House Unit G</td>
                <td>6.32</td>
                <td>£2.20</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Vestry Hall</td>
                <td>12.44</td>
                <td>£1.20</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <h4 class="mb-2">Worst Performers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - Teaching Centre</td>
                <td>2.33</td>
                <td>£0.94</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Villiers House Haven Green</td>
                <td>1.44</td>
                <td>£0.54</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Paragon House Unit H</td>
                <td>0.32</td>
                <td>£0.23</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Waste Data</h2>
        <span><i class="fad fa-dumpster fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">1,544 kWh</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">12 kWh/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Usage per area</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£345.43</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">£2.12/m<sup>2</sup></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Cost per area</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-6">
          <h3 class="mb-2">Usage</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
        <div class="col-6">
          <h3 class="mb-2">Cost</h3>
          <p class="mb-0">Total usage: 1,544 kWh</p>
          <p>Previous usage: 1,323 kWh</p>
          <Chart :options="energyChartOptions" />
        </div>
      </div>
      <h3 class="mb-2">Asset Performance</h3>
      <p>Highest and lowest performing assets excluding any zero-usage assets.</p>
      <div class="row">
        <div class="col-6">
          <h4 class="mb-2">Best Perfomers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>3.34</td>
                <td>£2.43</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Paragon House Unit G</td>
                <td>6.32</td>
                <td>£2.20</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Vestry Hall</td>
                <td>12.44</td>
                <td>£1.20</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <h4 class="mb-2">Worst Performers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - Teaching Centre</td>
                <td>2.33</td>
                <td>£0.94</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Villiers House Haven Green</td>
                <td>1.44</td>
                <td>£0.54</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Paragon House Unit H</td>
                <td>0.32</td>
                <td>£0.23</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Emissions</h2>
        <span><i class="fad fa-smog fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">43.32 MTCO2e</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">CO<sub>2</sub> Emissions</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">43.32 MTCO2e/m<sub>2</sub></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">CO<sub>2</sub> Emissions</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">43.32 MTCO2e/m<sub>2</sub></span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">CO<sub>2</sub> Emissions</span>
        </div>
      </div>
      <div class="row push">
        <div class="col-12">
          <Chart :options="energyChartOptions" />
        </div>
      </div>
      <h3 class="mb-2">Asset Performance</h3>
      <p>Highest and lowest performing assets excluding any zero-usage assets.</p>
      <div class="row">
        <div class="col-6">
          <h4 class="mb-2">Best Perfomers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage <br /><small style="white-space: pre">kWh/m<sub>2</sub></small>
                </th>
                <th>
                  Cost <br /><small style="white-space: pre">£/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>3.34</td>
                <td>£2.43</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Paragon House Unit G</td>
                <td>6.32</td>
                <td>£2.20</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Vestry Hall</td>
                <td>12.44</td>
                <td>£1.20</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <h4 class="mb-2">Worst Performers</h4>
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>#</th>
                <th>Asset Name</th>
                <th>
                  Usage<br /><small style="white-space: pre">kg CO2e/m<sub>2</sub></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-center">1</th>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>3.34</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>Paragon House Unit G</td>
                <td>6.32</td>
              </tr>
              <tr>
                <th class="text-center">3</th>
                <td>Vestry Hall</td>
                <td>12.44</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Projects</h2>
        <span><i class="fad fa-tasks fa-2x"></i></span>
      </div>
      <div class="report-stats">
        <div class="report-stat">
          <span class="report-stat--value">12</span>
          <span class="report-stat--change"
            ><small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Pending</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">3</span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">In Progress</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">43</span
          ><span class="report-stat--change">
            <small class="text-danger"><i class="fa fa-long-arrow-down"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Completed On-time</span>
        </div>
        <div class="report-stat">
          <span class="report-stat--value">12</span
          ><span class="report-stat--change">
            <small class="text-success"><i class="fa fa-long-arrow-up"></i> 25%</small> </span
          ><br />
          <span class="report-stat--label">Completed Late</span>
        </div>
      </div>
      <h3 class="mb-2">Summary</h3>
      <p>Summary of all projects.</p>
      <div class="row">
        <div class="col-12">
          <table class="table table-borderless table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th>Project Name</th>
                <th>Asset</th>
                <th>Status</th>
                <th>Tasks Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LED Retrofit</td>
                <td>Paragon House Unit G</td>
                <td>In Progress</td>
                <td>12/36 (33%)</td>
              </tr>
              <tr>
                <td>Solar Panel Installation</td>
                <td>St Mary's Road - Teaching Centre</td>
                <td>In Progress</td>
                <td>12/54 (23%)</td>
              </tr>
              <tr>
                <td>Solar Panel Installation</td>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>Completed</td>
                <td>54/54 (100%)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>

    <ReportPage>
      <div class="report-page--title">
        <h2>Data Summary</h2>
        <span><i class="fad fa-tasks fa-2x"></i></span>
      </div>
      <h3 class="mb-2">Assets</h3>
      <p>24 assets were used in this report.</p>
      <div class="row">
        <div class="col-12">
          <table class="table table-vcenter">
            <thead class="bg-primary text-white">
              <tr>
                <th colspan="4">Asset Details</th>
                <th colspan="3">Usage (kWh)</th>
                <th colspan="3">Cost</th>
                <th colspan="3">Emissions</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Location</th>
                <th>Type</th>
                <th>Size</th>
                <th>Electricity</th>
                <th>Gas</th>
                <th>Water</th>
                <th>Electricity</th>
                <th>Gas</th>
                <th>Water</th>
                <th>Electricity</th>
                <th>Gas</th>
                <th>Water</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LED Retrofit</td>
                <td>Paragon House Unit G</td>
                <td>In Progress</td>
                <td>12/36 (33%)</td>
              </tr>
              <tr>
                <td>Solar Panel Installation</td>
                <td>St Mary's Road - Teaching Centre</td>
                <td>In Progress</td>
                <td>12/54 (23%)</td>
              </tr>
              <tr>
                <td>Solar Panel Installation</td>
                <td>St Mary's Road - H Block (Hospitality)</td>
                <td>Completed</td>
                <td>54/54 (100%)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ReportPage>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import 'highcharts/css/highcharts.css';

import ReportPage from '@/components/ReportPage';

const baseChartOptions = {
  credits: {
    enabled: false
  }
};

export default {
  name: 'Report',
  components: {
    Chart,
    ReportPage
  },
  data() {
    return {
      asset: {
        name: 'abv'
      },
      energyChartOptions: {
        ...baseChartOptions,
        chart: {
          type: 'line',
          styledMode: true,
          height: '300px'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Cost (£)'
          },
          min: 0,
          plotlines: {
            width: 0
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true,
              radius: 3
            }
          },
          line: {
            marker: {
              symbol: 'circle'
            }
          }
        },
        series: [
          {
            name: 'Current Period',
            data: [
              { y: 88341.4, x: 1581724800000, name: 'Feb 20' },
              { y: 87427.6, x: 1584230400000, name: 'Mar 20' },
              { y: 70566.7, x: 1586908800000, name: 'Apr 20' },
              { y: 74612.9, x: 1589500800000, name: 'May 20' },
              { y: 68180.8, x: 1592179200000, name: 'Jun 20' },
              { y: 76034.3, x: 1594771200000, name: 'Jul 20' }
            ]
          },
          {
            name: 'Previous Period',
            data: [
              { y: 105566, x: 1581724800000, name: 'Feb 19' },
              { y: 115172, x: 1584230400000, name: 'Mar 19' },
              { y: 112632, x: 1586908800000, name: 'Apr 19' },
              { y: 117257, x: 1589500800000, name: 'May 19' },
              { y: 145295, x: 1592179200000, name: 'Jun 19' },
              { y: 186415.2, x: 1594771200000, name: 'Jul 19' },
              { y: 105095.3, x: 1597449600000, name: 'Aug 19' },
              { y: 128358.5, x: 1600128000000, name: 'Sep 19' },
              { y: 108466.4, x: 1602720000000, name: 'Oct 19' },
              { y: 92744, x: 1605398400000, name: 'Nov 19' },
              { y: 89041.7, x: 1607990400000, name: 'Dec 19' },
              { y: 93076.5, x: 1610668800000, name: 'Jan 20' }
            ]
          }
        ]
      },
      pieChartOptions: {
        ...baseChartOptions,
        chart: {
          type: 'pie',
          styledMode: true
        },
        title: {
          text: ''
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f}% ({point.percentage:.1f}%)',
              connectorShape: 'straight',
              distance: 40,
              padding: 5
            }
          }
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [
              {
                name: 'Education',
                y: 70
              },
              {
                name: 'Retail',
                y: 10
              },
              {
                name: 'Office',
                y: 8
              },
              {
                name: 'Industrial',
                y: 6
              },
              {
                name: 'Retail',
                y: 4
              },
              {
                name: 'Hotel',
                y: 2
              }
            ]
          }
        ]
      }
    };
  }
};
</script>
<style lang="scss">
$primary: #3c5c61;
$secondary: #4d9575;
$faded: #82979a;

.report {
  .fad {
    --fa-primary-color: #{$primary};
    --fa-secondary-color: #{$secondary};
  }

  &-page {
    display: flex;
    flex-direction: column;

    width: 1000px;
    height: 1410px;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $primary !important;

      small {
        color: $faded !important;
      }
    }

    p {
      color: $primary !important;

      small {
        color: $faded !important;
      }
    }

    background-color: #fff;
    padding: 64px;
    margin-bottom: 32px;

    &--title {
      color: $primary;
      display: flex;
      justify-content: space-between;
      align-items: middle;
    }
  }

  &-logo {
    text-align: right;
  }

  &-header {
    margin-bottom: 64px;
  }

  &-body {
    flex-grow: 1;
  }

  &-footer {
    text-align: center;
    margin-top: 64px;
  }

  &-title {
    margin-top: 256px;
  }

  &-contents {
    margin: 0 32px 0 32px;

    &--line {
      color: $secondary !important;
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
    }
  }

  &-stats {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin: 32px 0 64px 0;
  }

  &-stat {
    font-size: 20px;

    &--value {
      font-size: 24px;
    }

    &--change {
      font-size: 20px;
      margin-left: 6px;
    }

    &--label {
      color: $faded;
    }
  }
}

.highcharts-title {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  fill: $primary;
  font-weight: bold;
}

.highcharts-data-label text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  font-size: 16px;
  fill: $primary;
  font-weight: 400;

  .highcharts-strong {
    font-weight: 400;
  }
}

.highcharts-data-label-connector {
  fill: none !important;
}

.highcharts-color-0 {
  fill: $primary;
  stroke: $primary;
}

.highcharts-color-1 {
  fill: #3a7973;
  stroke: #3a7973;
}

.highcharts-color-2 {
  fill: $secondary;
  stroke: $secondary;
}

.highcharts-color-3 {
  fill: #79af6c;
  stroke: #79af6c;
}

.highcharts-color-4 {
  fill: #b6c35f;
  stroke: #b6c35f;
}

.highcharts-color-5 {
  fill: #ffd15e;
  stroke: #ffd15e;
}
.highcharts-axis-labels {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  fill: $primary;
  font-size: 12px;
}

.highcharts-axis .highcharts-axis-title,
.highcharts-legend-item > text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  fill: $primary;
  font-size: 14px;
  font-weight: 600;
}

.highcharts-axis .highcharts-axis-line {
  stroke-width: 2px;
  stroke: $faded;
}
</style>
