var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_c('small', {
    class: {
      'text-success': _vm.getPctDiff(_vm.current, _vm.previous) * (_vm.inverse ? -1 : 1) > 0,
      'text-danger': _vm.getPctDiff(_vm.current, _vm.previous) * (_vm.inverse ? -1 : 1) < 0,
      'text-muted': _vm.getPctDiff(_vm.current, _vm.previous) === 0
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-long-arrow-up': _vm.getPctDiff(_vm.current, _vm.previous) * (_vm.inverse ? -1 : 1) > 0,
      'fa-long-arrow-down': _vm.getPctDiff(_vm.current, _vm.previous) * (_vm.inverse ? -1 : 1) < 0,
      'fa-minus': _vm.getPctDiff(_vm.current, _vm.previous) === 0
    }
  }), _vm._v(" " + _vm._s(_vm.getPctDiff(_vm.current, _vm.previous, _vm.abs).toFixed(_vm.precision)) + "%")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }